
// Libraries
import * as React from 'react'

// Services
import UserService from '../services/user'

// Components
import Layout from '../components/layout'
import DuoSection from '../components/duoSection'
import Seo from '../components/seo'
import InputFactory from '../components/inputFactory'
import Button from '../components/button'

class ResetPasswordPage extends React.Component {
	// Set initial state
	state = {
		formData: {},
		errors: {},
		isLoading: false
	}

	render() {
		// Get form data and errors from state
		const { formData, errors, isLoading } = this.state

		return <Layout className="profile-change-password nav-blue-half">
			<Seo title="Profile Change Password" />
			<DuoSection ProfileChangePassword>
				<div>
					<div className="content-wrap">
						<h1 className="color--endeavour">Change Password</h1>
					</div>
				</div>
				<div>
					<div className="content-wrap">
						<form onSubmit={this.handleSubmit} autoComplete="off">
							<InputFactory
								type="email"
								label="Email Address*"
								name="email"
								placeholder="Email"
								value={formData?.email}
								error={errors?.email}
								onChange={this.handleInputChange}
								required
							/>
							<InputFactory
								type="password"
								label="New Password*"
								name="password"
								placeholder="Min 6 characters"
								value={formData?.password}
								error={errors?.password}
								onChange={this.handleInputChange}
								className="new-text-input"
							/>
							<InputFactory
								type="password"
								label="Confirm Password*"
								name="password_confirmation"
								placeholder="Min 6 characters"
								value={formData?.password_confirmation}
								error={errors?.password_confirmation}
								onChange={this.handleInputChange}
								className="new-text-input"
							/>

							<Button type="submit" isLoading={isLoading} block fixedButton>Change Password</Button>
						</form>
					</div>
				</div>
			</DuoSection>
		</Layout>
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {formData} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				formData[scope][name] = value
			}
			else {
				formData[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete formData[scope][name]
			}
			else {
				delete formData[name]
			}
		}

		// Update state of file input values
		this.setState({ formData })
	}

	handleSubmit = async (event) => {
		event.preventDefault()

		this.setState({
			isLoading: true
		})

		// Get form data from state
		const { formData } = this.state

		// Add token to form data
		const { token } = this.props
		formData['token'] = token

		// Run forgotten password method from user service
		const userService = new UserService()
		const response = await userService.resetPassword(formData)

		// Check if request was successful or if any validation errors
		if(!response?.success || response?.errors) {
			// Add validation errors to state
			this.setState({
				errors: response?.errors,
				isLoading: false
			})
		}
		else {
			return this.props.navigate('/reset-password-success/')
		}
	}
}

export default ResetPasswordPage
